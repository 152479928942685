@import 'src/styles/mixins';

.detailsRow {
  width: 100%;
  border-bottom-style: dotted;
  justify-content: space-between;
  display: flex;
  margin-bottom: 2rem;
}

  .successIcon {
    width: 4rem;
    height: 4rem;
    color: green;
    margin-left: 2rem
  }

  .backCTA {
    font-size: 1.5rem;
    padding-right: 2rem;
  }
  .text {
    font-size: 2.5rem;
  }
  .header {
    padding: 2rem 0;
    display: flex;
    align-items: center;
  }


