@import 'src/styles/mixins';

.filterWrapper {
  display: flex;
  flex-wrap: wrap;
}

.filterInput {
  margin-right: $gapL;
  max-width: 23rem;
  min-width: 23rem;

  input {
    padding-bottom: ($gapM - 0.2rem);
  }
}

.button {
  margin: 0 1rem 0 0;
}

.selectInputLabel {
  @include font-size-s;

  color: $textColorLight;
  font-weight: 200;
  transform: translate($gapM, 1.3rem) scale(1);
  z-index: $zIndexTop;
}

.error {
  @include font-size-s;

  color: $errorColor;
}