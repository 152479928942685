@import '../mixins';

/* stylelint-disable selector-class-pattern */

.MuiDataGrid {
  &-cell {
    @include font-size-s;

    border-bottom: 1px solid $lineColor;
    letter-spacing: 0;
    line-height: 1.8rem;
  }

  &-columnsContainer {
    background: $additional_blue_50;
  }

  &-columnHeaderTitle {
    @include font-size-s;

    font-weight: 300;
  }

  &-iconSeparator {
    display: none;
  }

  &-row {
    color: $textColor;
  }

  &-root {
    border: 0;

    .MuiDataGrid-columnsContainer {
      border-bottom: 0;
      border-left: 1px solid $additional_gray_blue_50;
      border-right: 1px solid $additional_gray_blue_50;
    }

    .MuiDataGrid-columnHeader {
      color: $textColorLight;
      flex: 1;
      width: auto;

      &:focus {
        outline: none;
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0;
    }

    .MuiDataGrid-row {
      cursor: pointer;
      width: 100%;
      border-left: 1px solid $additional_gray_blue_50;
      border-right: 1px solid $additional_gray_blue_50;
      &:hover {
        .MuiDataGrid-cell {
          background-color: $additional_gray_blue_50;
        }
      }
      &:last-of-type {
        border-bottom: 1px solid $additional_gray_blue_50;
        .MuiDataGrid-cell:first-of-type {
          border-bottom-left-radius: $borderRadius_lg;
        }
        .MuiDataGrid-cell:last-of-type {
          border-bottom-right-radius: $borderRadius_lg;
        }
      }
    }

    .MuiDataGrid-cell {
      flex: 1;
      width: auto;
      background-color: $white;

      &:focus {
        outline: none;
      }

      &:last-of-type {
        border-right: 0;
      }
    }

    .MuiDataGrid-menuIcon {
      display: none;
    }
  }
}

.Mui-odd {
  background-color: $lightBgColor;
}

.MuiDataGrid-overlay {
  z-index: 10;
}
