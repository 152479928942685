@import 'src/styles/mixins';

.wrapper {
  padding: 2.5rem 4rem;
  width: 72rem;
}

.callback_wrapper {
  width: 100%;
}

.title {
  @include font-size-l;

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;

  &__close {
    font-size: 2.5rem;
  }
}

.callback_title {
  @include font-size-l;

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
  font-weight: 600;
}

.row {
  display: flex;
  padding: 1.6rem 0;
  width: 100%;
}

.button {
  margin: 0 1rem 0 0;
}

.label {
  @include font-size-xs;

  color: $textColorLight;
  margin-bottom: $gapS;
}

.value {
  @include font-size-s;

  align-items: center;
  color: $textColor;
  display: flex;
  height: 4.5rem;
  overflow: hidden;
}
.valueExtended {
  height: auto;
  overflow: visible;
}

.actionButtonsContainer {
  position: absolute;
  right: 4rem;
  bottom: 2rem;
  gap: 1rem;

}
