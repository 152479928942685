@import 'src/styles/variables';

.icon {
  height: 32px;
  width: 32px;
  border-radius: 8px;
}

.name {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.67rem;
}

.cardContent {
  margin: 1.5rem 2rem;
}

.card {
  border-radius: $borderRadius_lg;
}

.switch {
  display: flex;
  justify-content: flex-end;
  margin: 1.16rem 0;
  width: 100%;
}

.tabsContainer {
  margin-bottom: 2rem;
}

.tabContentBox {
  background-color: $white;
  padding: 2.3rem 1.16rem;
  border-radius: $borderRadius_lg;
}

.productSpacing {
  padding: 2rem 3rem;
  margin-bottom: 1.16rem;
}

.headerPadding {
  margin-bottom: 1rem;
}

.productContainer {
  padding: 2rem 3rem;
}

.columnHeader {
  width: 100%;
  margin-bottom: 1.16rem;
}

.fitContent {
  height: fit-content;
}

.tabContent {
  background-color: $primaryBackgroundColor;
  display: flex;
  border: none;
  padding: 0;
  gap: 2.5rem;
}

.productItem {
  .label {
    color: $gray_400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .value {
    font-size: 14px;
    line-height: 21px;
  }
  &:first-child {
    margin-bottom: 3rem;
  }
}
