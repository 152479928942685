@import 'src/styles/mixins';

.main {
  display: flex;
}

.title {
  @include font-size-xl;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.pageHeader {
  margin-bottom: $gapM;
}

.filtersContainer {
  margin-bottom: $gapL;
  display: flex;
  justify-content: space-between;
}

.tableHeader {
  margin-bottom: $gapL;
  margin-top: $gapL;
}

.tableTitle {
  @include font-size-l;
}

.searchInput {
  width: 33rem;
}

.tableContainer {
  display: flex;
  flex: 1 1;
  flex-flow: column;
}

.orderNo {
  color: $textColorLight;
}

.statusCellNeutral {
  color: $textColorLight;
}

.statusCellPositive {
  color: $successColor;
}

.statusCellNegative {
  color: $errorColor;
}

.statusCellWarning {
  color: $warningColor;
}

.download {
  margin-right: $gapL;
  width: 10rem;
}

.placeOrder {
  margin-right: $gapL;
}

.downloadSearchContainer {
  display: flex;
  flex-direction: row;
}

.summaryBoxContainer {
  margin: $gapM 0;
}

.summaryBox {
  border: 0;
  border-radius: $borderRadius_lg;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  flex-grow: 1;
  margin-left: -8px;
  min-height: 128px;

  .headline {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .boxTitle {
      @include font-size-s;
      display: inherit;
      gap: 0.5rem;
      svg {
        color: $blue_500;
      }
    }
  }
  .details {
    display: flex;
    align-items: flex-end;
    font-size: 1.16rem;

    p {
      @include font-size-m;
      font-weight: 600;
      margin: 0 0.5rem;
      text-wrap: nowrap;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $black_600;
    }
    .grayed {
      @include font-size-m;
      color: $gray_400;
      margin: 0 0.5rem;
    }
  }
  .detailsRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: $gray_400;
  }

  .percentBox {
    border-radius: $borderRadius_md;
    padding: 6px 10px;
    min-width: 4rem;
    text-align: center;
  }
}

.good {
  background-color: $cyan_50;
  color: $cyan_500;
}
.bad {
  background-color: $orange_100;
  color: $orange_600;
}
.critical {
  background-color: $red_100;
  color: $red_600;
}
