@import 'src/styles/mixins';

.emptyTableOverlay {
  background: transparent;
  display: flex;
  padding: 2rem;
  position: absolute;
  text-align: center;
  top: 2rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-bottom-left-radius: $borderRadius_lg;
  border-bottom-right-radius: $borderRadius_lg;

}

.positionRelative {
  position: relative;
  top: unset;
}

.image {
  margin: auto;
  max-width: 300px;
}
