@import 'src/styles/mixins';

.disbursementCard {
  align-items: center;
  background-color: $inputBgColor;
  border-color: $inputBgColor;
  display: flex;
  min-height: 10rem;

  .disbursementCardContent {
    padding: 2rem;
    position: relative;
    width: 100%;
  }

  .number {
    color: $textColor;
    font-size: 2.4rem;
    line-height: 3.6rem;
  }

  .title {
    @include font-size-l;

    color: $textColorLight;
  }
}
