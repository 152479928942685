@import "src/styles/mixins";

.navbar {
  box-shadow: none;

  @include desktop-only {
    max-width: $desktopMainMaxWidth;
  }

  @include tablet-only {
    width: 100%;
  }
}

.navBarContent {
  @include main-container-side-paddings;

  align-items: center;
  display: flex;
  height: 6rem;
  justify-content: space-between;
  width: 100%;

  @include desktop-only {
    max-width: $desktopMainMaxWidth;
  }
}

.logoLink {
  display: flex;
}

.logo {
  order: 0;
}

.customLogo {
  max-height: 1.7rem;
}

.links {
  align-items: center;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  order: 2;
}

.toolbar {
  order: 3;
  text-align: right;
  display: flex;
  gap: $gapM;
  justify-content: space-between;
}

.linkWrapper {
  height: 6rem;
  align-content: center;
  display: flex;
}

.navLink {
  @include font-size-s;
  display: flex;
  align-items: center;
  color: $textColorLight;
  margin: 0 $gapL;

  &.activeLink,
  &:hover {
    color: $textColor;
    position: relative;
    text-decoration: none;

    &::after {
      background-color: $primaryColor;
      border-bottom: 2px solid transparent;
      border-radius: $borderRadius;
      bottom: -0.4rem;
      content: "";
      height: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}

.dropdownIconButtons {
  font-size: 2rem;
  margin-right: $gapS;
  max-width: 15rem;

  /* stylelint-disable-next-line selector-class-pattern */
  .MuiButton-label {
    overflow: hidden;
    color: $white;
    font-size: 1.2rem;
  }
}
.menuItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logoLinkContainer {
}

.menuDropdownButton {
  border-radius: 2rem;
  padding: 4px 8px 4px 4px;
  border: 1px solid $gray_50;
  min-height: 4rem;
  color: $gray_600;

  div {
    margin: 0 $gapS;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.menuItemIcon {
  height: 1.25rem;
  margin-right: $gapS;
  width: 1.25rem;
}

.userMenuItemIcon {
  border-radius: $borderRadius_lg;
  width: 32px;
  height: 32px;
  margin-right: $gapS;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.userSettings {
  background-color: $orange;
}

.notificationButton {
  padding: 0;
  width: 4rem;
  min-width: 4rem;

  span {
    margin: 0;
  }
}
.anchor {
  text-decoration: none;
}
