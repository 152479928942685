@import 'src/styles/mixins';
@import 'src/styles/variables';
.box {
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  padding: 2 * $gapL;
  background-color: $white;

  @include tablet-only {
    padding: $gapM;
  }
}
