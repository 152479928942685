@import 'src/styles/variables';

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-radius: 6px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: $additional_blue_50;
  color: #bdbdbd;
  outline: none;
  height: 168px;
  transition: border .24s ease-in-out
};

.focusedStyle  {
  border-color: #2196f3
};

.acceptStyle  {
  border-color: #00e676
};

.rejectStyle  {
  border-color: #ff1744
};

.container {
  width: 100%;
}
