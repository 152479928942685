@import 'src/styles/mixins';
@import 'src/styles/variables';

.background {
  padding: 6px 10px;
  border-radius: $borderRadius_xs;
  font-weight: 600;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 7rem;
}

.success {
  background-color: $green_50;
  color: $green_500;
}

.error {
  background-color: $red_100;
  color: $red_500;
}

.neutral {
  background-color: $additional_gray_50;
  color: $gray_500;
}
