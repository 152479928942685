@import 'src/styles/variables';
:global {
  .MuiButton-root {
    font-size: 1rem;
    border-radius: $borderRadius_md;
    
  }
  .MuiButton-outlinedPrimary {
    background-color: $white;
    border-color: $gray_100;
  }
}
