@import "src/styles/mixins";
@import "src/styles/variables";

.title {
  text-align: center;
  font-size: 48px;
  margin: 0;
  padding-bottom: 24px;
}

.iframeWrapper {
  height: 600px;
  padding-bottom: 48px;

  iframe {
    outline: none;
    border: 1px solid $gray_400;
    width: 100%;
    height: 100%;
  }
}
