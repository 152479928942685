@import "src/styles/mixins";

.link {
  color: $primaryColor;
  cursor: pointer;
}

.input {
  display: flex;
  margin-top: $gapL;
}

.error {
  @include font-size-s;

  color: $errorColor;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.signInRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: $gapL;
  max-width: $inputMaxWidth;
}

.terms {
  margin-top: $gapS;
  margin-bottom: 32px;
}

.button {
  float: right;
}

.center {
  @include mobile-only {
    text-align: center;
  }
  display: flex;
  margin-top: $gapL;
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  @include font-light;

  color: $primaryColor;
  cursor: pointer;
  margin-bottom: $gapL;
  margin-top: $gapL;

  @include tablet-only {
    margin-bottom: $gapS;
  }
}

.boxRow {
  display: flex;

  @include mobile-only {
    flex-direction: column;
  }
}

.welcomeMessage {
  font-size: 3.2rem;
  margin-bottom: $gapL;
  margin-top: 10vh;
}

.signInRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.register {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: $gapL;
  text-align: center;
  width: 50%;

  @include tablet-only {
    margin-bottom: $gapL;
    margin-top: $gapL;
  }

  @include mobile-only {
    display: none;
  }
}

.registerHeader {
  @include font-light;
  @include font-size-l;

  margin-bottom: $gapM;
}

.registerLink {
  @include font-size-l;

  margin-bottom: $gapM;

  &:visited {
    color: $primaryColor;
  }
}

.registerIllustration {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include mobile-only {
    display: none;
  }

  @include tablet-only {
    max-width: 30rem;
  }
}

.boxHeader {
  @include font-light;
  @include font-size-l;

  @include tablet-only {
    @include font-size-m;
    display: flex;
    justify-content: center;
    margin-bottom: $gapM;
    margin-top: $gapM;
  }
}

.logo {
  @include hide-on-tablet;

  margin-bottom: $gapL;
}

.loginForm {
  box-sizing: border-box;
  width: 413px;
  z-index: 1000;

  @include mobile-only {
    width: 100%;
  }

  @include tablet-only {
    margin-bottom: $gapL;
    margin-top: $gapL;
  }
}

.hideOnTablet {
  @include hide-on-tablet;
}

.checkboxInvalid {
  path {
    color: $errorColor;
  }
}

.bankRow {
  @include font-light;
  @include font-size-l;

  align-items: center;
  display: flex;
  margin-bottom: $gapM;
  margin-top: $gapM;

  @include tablet-only {
    display: none;
  }
}

.institutionLogo {
  margin-left: $gapL;
  margin-right: $gapL;
}

.errorBanner {
  @include font-light;
  @include font-size-s;

  align-items: center;
  background: $errorBgColor;
  border: 2px solid $errorColor;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $errorColor;
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 100%;
}

.styledReceipt {
  width: 80%;
  padding: 1rem 1rem 2rem;
  filter: drop-shadow(2px 4px 2px #adadad);
  opacity: 0.6;
  position: relative;
  z-index: 0;
}
.styledReceipt:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: linear-gradient(45deg, #ffffff, #fff);
  --mask: conic-gradient(
      from -45deg at bottom,
      #0000,
      rgba(255, 255, 255, 0.32) 1deg 89deg,
      #0000 90deg
    )
    50%/30px 100%;
  -webkit-mask: var(--mask);
  mask: var(--mask);
}

.receiptRow {
  display: flex;
  margin-bottom: 1rem;
  border-bottom-style: dotted;
  justify-content: space-between;
  width: 100%;
}

.receiptContent {
  display: flex;
  flex-direction: column;
  margin: 1rem 5rem;
}
