@import 'src/styles/mixins';

$checkboxIconSize: 2.4rem;

.checkbox {
  color: $checkboxColor;
  padding: $gapS;

  svg {
    @include size($checkboxIconSize);
  }
}

.row {
  @include default-transition;

  align-items: center;
  display: flex;
  margin-left: -$gapS;

  &_translucent {
    opacity: 0.5;
  }

  .checkbox {
    margin-right: $gapS;
  }
}
