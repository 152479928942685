@import 'src/styles/mixins';

.staticInput {
  background-color: white;
}

.column {
  background-color: white;
  border-radius: 0%;
}

.container {
  padding: 3rem;
  background-color: $white;
  border-radius: $borderRadius_lg;

  .inputWrapper {
    margin-bottom: 2.5rem;
    &:last-of-type {
      margin-bottom: 3rem;
    }
  }

  button {
    margin-top: 2rem;
  }
}

.root {
  gap: 2rem;
}

.description {
  font-weight: 300;
}

.shrinkContainer {
  height: fit-content;
}
