@import "src/styles/mixins";

.icon {
  margin-left: -$gapS;
  margin-right: $gapS;

  &_end {
    margin-left: $gapS;
    margin-right: -$gapS;
  }

  g,
  path {
    @include default-transition;
  }
}

.label {
  @include text-overflow-ellipsis;
}

.button {
  @include default-transition;
  @include rounded-corners;

  background-color: $white;
  border: 1px solid $gray_100 !important;
  height: $basicElementHeight;
  padding: 10px;

  &_disabled {
    .addIcon {
      path {
        fill: $disabledColor;
      }
    }

    .saveIcon {
      path {
        stroke: $disabledColor;
      }
    }

    .arrowIcon,
    .closeIcon {
      g {
        fill: $disabledColor;
      }
    }
  }

  &:hover {
    border-width: 1px !important; // sass-lint:disable-line no-important
    background-color: $white;
    border-color: $gray_200 !important;
  }
}

.hidden {
  opacity: 0;
}
