@import 'src/styles/mixins';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copied {
  @include font-size-xs;
}

.button {
  min-width: unset;
  border-radius: 50%;
}