@import 'src/styles/mixins';
@import 'src/styles/variables';
.wrapper {
  padding: 2.5rem 4rem;
  width: 72rem;
}

.title {
  @include font-size-xl;

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;

  &__close {
    font-size: 2.5rem;
  }
}

.label {
  @include font-size-xs;

  color: $textColorLight;
  margin-bottom: $gapS;
}

.buttonRow {
  padding-top: 24px;
}

.row {
  display: flex;
  padding: 1.6rem 0;
  width: 100%;
}

.fileInput {
  &::-webkit-file-upload-button {
    border-color: $primaryColor;
    border-width: 2px !important; // sass-lint:disable-line no-important
    height: $basicElementHeight;
    padding-left: $gapM;
    padding-right: $gapM;
  }
}

.modalBody {
  min-width: 500px;
  width: 500px;
}

.modalContainer {
  border-radius: 14px;
  padding: 2.66rem;
  min-width: 500px;
}

.iconWrapper {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue_100;
  border-radius: $borderRadius_md;
  margin-right: 1.5rem;
}

.stepWrapper {
  border-radius: $borderRadius_md;
  background-color: $lightBgColor;
  padding: 2rem 1.25rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  p {
    padding: 0;
  }
}

.stepBullet {
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: $borderRadius_md;
  margin-right: 1.5rem;
}

.title {
  @include font-size-m;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.description {
  color: $textColorLight;
}

.batchButton {
  padding: 0.83rem 1.66rem;
  border-radius: $borderRadius_md;
  border: 1px solid $gray_100;
  margin: 1rem 0.25rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
.selected {
  background-color: $gray_600;
  color: $white;
}
.actionButton {
  padding: 11px 20px;
}

.orderId {
  @include font-size-s;
}

.orderIdLabel {
  @include font-size-s;
  color: $gray_400;
}
