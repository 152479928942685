@import "src/styles/mixins";
@import "src/styles/variables";

.wrapper {
  width: 100%;
  min-height: 1024px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $pos_400;
}

.textWrapper {
  margin: 0 auto;
  text-align: center;
  margin-top: 122px;

  h1 {
    font-size: 64px;
    font-weight: 400;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: $gray_500;
  }
}

.phone1 {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(-5%);
  z-index: 200;
}

.phone2 {
  position: absolute;
  bottom: 0;
  right: 50%;
  z-index: 100;
  transform: translateX(105%);
}

.cloud1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 300;
}

.cloud2 {
  position: absolute;
  bottom: 0;
  right: 0;
}
