@import 'src/styles/mixins';

.terms {
  align-items: center;
  display: flex;

  @include tablet-only {
    @include font-size-s;
  }
}

.label {
  @include font-light;
  @include font-size-s;

  &_full {
    @include tablet-only {
      display: none;
    }
  }

  &_short {
    @include desktop-only {
      display: none;
    }
  }
}

.link {
  @include font-light;
  @include font-size-s;

  color: $primaryColor;
  cursor: pointer;
}
