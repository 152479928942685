.container {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  background-color: white;
}
