@import 'src/styles/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formWrapper {
  padding: 1rem 0;
  width: 100%;

  &--reset {
    width: 40rem;
  }
}

.btnWrapper {
  padding: 2.25rem 0 0;
  text-align: center;
}

.btnWrapperChangePassword {
  padding: 2.25rem 0 0;
}

.description {
  @include font-size-l;

  color: var(--textColor);
  line-height: 2.4rem;
  max-width: 39.5rem;
}

.input {
  display: flex;
  margin-top: $gapL;
  width: 100%;

  &--center {
    margin: 0 auto;
  }
}

.error {
  @include font-size-m;

  color: $errorColor;
}
