@import "src/styles/mixins";

/* stylelint-disable no-descending-specificity */

.fieldWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.field {
  @include default-transition;

  width: 100%;
}

.input {
  @include font-size-s;

  border: 0;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $textColor;
  min-height: $basicElementHeightMinusInputBorders;
  padding: $gapM ($gapM - 0.2rem) ($gapS - 0.1rem);
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &_textarea {
    margin-top: ($gapM - 0.1rem);
    min-height: 1.8rem;
    padding: 0 0.1rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @include default-transition;

    box-shadow: 0 0 0 3rem $inputBgColor inset !important;

    &:disabled {
      box-shadow: 0 0 0 3rem $white inset !important;
    }
  }

  &[type="number"] {
    appearance: textfield;
  }
}

.inputWrapper {
  @include rounded-corners;

  color: $gray_600;
  background: transparent !important;
  border: 1px solid $gray_100;
  border-radius: $borderRadius;
  margin-top: 0 !important;
  min-height: $basicElementHeight;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &::after,
  &::before {
    display: none;
  }

  &_disabled {
    background: none !important;
    border-color: $switchBgColor;
  }

  &_textarea {
    padding-bottom: 0;
    padding-top: 0;
  }

  &_focused {
    border-color: $gray_400;
  }

  &_error {
    border-color: $errorColor;
  }

  &_end {
    padding-right: 0;
  }
}

.error {
  @include font-size-s;

  color: $errorColor;
  margin-top: 0.1rem;

  &_absolute {
    bottom: -1.8rem;
    position: absolute;
  }
}

.label {
  @include font-size-s;

  color: $textColorLight !important;
  pointer-events: none;
  transform: translate($gapM, 1.3rem) scale(1);
  z-index: $zIndexTop;

  &_shrink {
    transform: translate($gapM, 0.6rem) scale(0.8) !important;
  }
}
