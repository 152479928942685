@import 'src/styles/mixins';

.main {
  display: flex;
}

.title {
  @include font-size-xl;
}

.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapM;
  width: 100%;
}

.headerButtons {
  display: flex;
}

.newLinkButton {
  margin-left: $gapM;
}

.tableHeader {
  margin-bottom: $gapL;
  margin-top: $gapL;
}

.tableTitle {
  @include font-size-l;
}

.searchWrapper {
  display: flex;
}

.searchInput {
  width: 19rem;
}

.paper {
  background: $white;
  border-radius: $borderRadius;
  opacity: 1;
  padding: 2rem;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 50rem;
}

.modal {
  align-items: center;
  display: flex;
  justify-content: center;

  &__title {
    margin-top: 0;
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.buttonInvalid {
  background-color: $errorColor;

  &:hover {
    background-color: darken($color: $errorColor, $amount: 1);
  }
}

.tableRow {
  cursor: pointer;

  .btnTableRow {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &:hover {
    .btnTableRow {
      opacity: 1;
    }
  }
}

.paymentLinkWrapper {
  align-items: center;
  background-color: $boxBgColor;
  border: solid 1px $lineColor;
  border-radius: $borderRadius;
  display: flex;
  height: 10rem;
  justify-content: center;
  position: relative;
  text-align: center;

  &WithIcon {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.3rem 3.5rem;
  }

  .text {
    @include font-size-l;

    color: $textColorLight;
    display: inline;
    margin: 0;
    padding: 0;
  }

  a {
    @include font-size-l;

    color: $textColor;
    margin-left: $gapS;
    margin-right: $gapM;
  }

  svg {
    max-width: 35rem;
  }
}

.filtersContainer {
  margin-top: $gapL;
}

.tableContainer {
  display: flex;
  flex: 1 1;
  flex-flow: column;
}
