@import "src/styles/mixins";
@import "src/styles/variables";

.card {
  min-height: 340px;
  border-radius: 14px;
  box-shadow: 0px 16px 16px -24px #27344480;
  padding: 0 24px;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 270px;
  overflow: hidden;
}

.contentContainer {
  position: relative;
  max-width: 215px;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  button {
    width: 100%;
  }
}

.textWrapper {
  padding-bottom: 40px;

  h2 {
    color: $gray_600;
    font-size: 20px;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    color: $gray_400;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.buttonWrapper {
  width: 100%;
}

.register {
  font-size: 13px;
  font-weight: 600;
  color: $gray_300;

  a {
    text-decoration: none;
    color: $gray_600;
  }
}
