// sass-lint:disable no-duplicate-properties

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  src: url('./Nunito-Regular.eot');
  src: url('./Nunito-Regular.eot?#iefix') format('embedded-opentype'), url('./Nunito-Regular.woff') format('woff'), url('./Nunito-Regular.ttf') format('truetype');
}

// Semi-bold set as normal, as it's the main font weight of app
@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  src: url('./Nunito-SemiBold.eot');
  src: url('./Nunito-SemiBold.eot?#iefix') format('embedded-opentype'), url('./Nunito-SemiBold.woff') format('woff'), url('./Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  src: url('./Nunito-Bold.eot');
  src: url('./Nunito-Bold.eot?#iefix') format('embedded-opentype'), url('./Nunito-Bold.woff') format('woff'), url('./Nunito-Bold.ttf') format('truetype');
}
