@import "src/styles/mixins";
@import "src/styles/variables";

.navBarContainer {
  border-bottom: 1px solid $lineColor;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $white;
  z-index: 10;
}

.mainContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 27rem; // prevent resizing for very small mobiles;
}

.main {
  @include main-container-side-paddings;

  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  @include desktop-only {
    max-width: $desktopMainMaxWidth;
  }
}

.logo {
  @include tablet-only {
    order: 1;
  }
}

.header {
  @include font-light;
  @include font-size-xl;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: $gapL;

  @include tablet-only {
    align-items: center;
    box-sizing: border-box;
    flex-flow: row wrap;
    font-size: 1.8rem;
    justify-content: space-between;
    padding-bottom: $gapL;
    padding-top: $gapL;
  }
}

.content {
  display: flex;
  flex: 1 1;
  flex-flow: column;

  @include desktop-only {
    padding-top: 2rem;
  }
}

.qaModeBanner {
  @include font-size-l;

  align-items: center;
  align-self: center;
  background: $errorBgColor;
  border: 1px solid $errorColor;
  border-radius: $borderRadius;
  color: $errorColor;
  display: flex;
  height: 5rem;
  justify-content: center;
  margin-top: $gapM;
  width: 100%;

  @include tablet-only {
    width: calc(100% - 3rem);
  }
}

.qaModeBannerText {
  &_full {
    @include tablet-only {
      display: none;
    }
  }

  &_short {
    @include desktop-only {
      display: none;
    }
  }
}

.copyright {
  @include font-size-s;

  color: $textColorLight;
  display: flex;
  flex-wrap: wrap-reverse;
  padding-bottom: $gapL;
  padding-top: $gapL;
  margin: 0 auto;

  @include desktop-only {
    max-width: $desktopMainMaxWidth;
  }
}

.footer {
  margin-top: auto;
  z-index: 10;
  width: 100%;
}

.link {
  @include font-light;

  color: $textColorLight;
  cursor: pointer;
  text-decoration: underline;

  &_termsAndConditions {
    margin-left: $gapL;
  }
}

.copyrightText {
  @include font-light;

  @include mobile-only {
    margin-top: $gapM;
    width: 100%;
  }
}

.copyrightLinks {
  display: flex;

  @include tablet-only {
    margin-left: $gapL;
  }

  @include mobile-only {
    flex: 1;
    justify-content: space-between;
    margin-left: 0;
    margin-left: $gapL;
  }
}

.bannerContainer {
  @include default-transition;

  height: 0;
  width: 100%;
  z-index: 10;

  &_shown {
    height: 4rem;
    margin-top: $gapM;
  }

  &_desktop {
    @include tablet-only {
      display: none;
    }
  }

  &_mobile {
    @include desktop-only {
      display: none;
    }
  }
}

.banner {
  @include default-transition;
  @include font-light;
  @include font-size-s;

  align-items: center;
  background: $primaryContentBackgroundColor;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $primaryColor;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  padding-left: $gapL;
  padding-right: $gapL;
  width: 100%;

  &_shown {
    opacity: 1;
  }

  &_success {
    background: $successBgColor;
    color: $textColor;
    justify-content: flex-start;
  }

  &_warn {
    background: $warningBgColor;
    color: $warningColor;
  }

  &_error {
    background: $errorBgColor;
    color: $errorColor;
  }
}

.bannerIcon {
  margin-right: $gapL;
}
