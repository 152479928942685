/// Variables

//BASE
// Gray
$black_600: #03131f;
$gray_600: #323f48;
$gray_500: #495963;
$gray_400: #6b8291;
$gray_300: #7f97ab;
$gray_200: #8aa5ba;
$gray_100: #b0c3d1;
$gray_50: #edeff1;
$white: #ffffff;

// Red
$red_600: #c82b3b;
$red_500: #de3143;
$red_100: #fef8f8;

// Orange
$orange_600: #ff6d1c;
$orange_100: #ffebdf;

// Blue
$blue_600: #0000c6;
$blue_500: #4546f7;
$blue_400: #767dfe;
$blue_300: #a0a2ff;
$blue_200: #cbc8ff;
$blue_100: #e7e5ff;
$blue_50: #f6f5ff;

// Light Blue
$light_blue_600: #007fd4;
$light_blue_100: #bde5ff;
$light_blue_50: #f3faff;

// Green
$green_500: #00c59a;
$green_50: #e8fffa;

// Purple
$purple_500: #7341ff;
$purple_50: #e6ddff;

// Cyan
$cyan_500: #02af89;
$cyan_50: #e8fffa;

// Additional Gray
$additional_gray_500: #495963;
$additional_gray_50: #edeff1;

// Additional Blue
$additional_blue_50: #f3f6fa;

// Additional Gray-Blue
$additional_gray_blue_50: #ebf2ff;

// Additional Gray-Light
$additional_gray_light_50: #e1e7f0;

// colors
$primaryColor: $blue_500;
$primaryContentBackgroundColor: $white;
$primaryRippleColor: $blue_300;
$successColor: #00a8c1;
$successBgColor: #ddf2f6;
$warningColor: #ff9a33;
$warningBgColor: #feeee1;
$errorColor: #f84c1e;
$errorBgColor: #fbe4e1;
$lightYellowBgColor: #fffcfb;
$disabledColor: #b1becf;
$inputBgColor: #eef4fa;
$switchBgColor: #e8eff7;
$checkboxColor: #dce1e7;
$textColor: $black_600;
$textColorLight: $gray_500;
$lineColor: #d7e1ea;
$lightBgColor: #f3f7fb;
$boxBgColor: $white;
$lightShadowColor: rgb(74 80 91 / 12%);
$primaryBackgroundColor: $additional_blue_50;
$positive: $green_500;
$orange: #ff7e5a;

// breakpoints in px unit
$breakpointSm: 360;
$breakpointMd: 768;
$breakpointLg: 1180;
$breakpointExtraLg: 1480;

//border-radius
$borderRadius_xs: 0.25rem;
$borderRadius_sm: 0.75rem;
$borderRadius_md: 0.83rem;
$borderRadius_lg: 1.16rem;

// font and base size
$mainFontFamily: "Nunito", "Open Sans", sans-serif;
$baseFontSize: 12px;
$inputMaxWidth: 39.5rem;
$basicElementHeight: 3.8rem;
$basicElementHeightMinusInputBorders: ($basicElementHeight - 0.4rem);
$borderRadius: $borderRadius_sm;
$desktopMainMaxWidth: 1110px;
$desktopSidebarWidth: 39rem;
$fontSizes: (
  xl: 3rem,
  l: 1.6rem,
  m: 1.34rem,
  s: 1.16rem,
  xs: 1rem,
);

// sizes
$baseGap: 0.285rem;
$gapS: $baseGap;
$gapM: 4 * $baseGap;
$gapL: 6 * $baseGap;
$gapXL: 10 * $baseGap;
// $gapL: 4 * $baseGap;
// $gapL: 6 * $baseGap;
// $gapXL: 10 * $baseGap;

// z-indexes
$zIndexNegative: -1;
$zIndexStandard: 1;
$zIndexTop: 2;
$zIndexOverlay: 3;

$brand_100: #1e73be;

$label_card_100: #d8f7f0;
$label_card_400: #008b6e;

$pos_400: #dfecfd;
