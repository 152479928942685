@import 'src/styles/mixins';

.main {
  display: flex;
}

.title {
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.filtersRoot {
  padding: 2rem 0;
}

.filterWrapper {
  display: flex;
  flex-wrap: wrap;
}

.filterInput {
  margin-bottom: 2rem;
  margin-right: 2rem;
  max-width: 28rem;
  min-width: 28rem;
}

.button {
  margin: 0 1rem 0 0;
}

.input {
  margin-top: 2rem;
}

.buttonInvalid {
  background-color: $errorColor;

  &:hover {
    background-color: darken($color: $errorColor, $amount: 1);
  }
}

.submitWrapper {
  margin-top: 2rem;
  text-align: right;
}

.paymentLinkWrapper {
  margin-top: 4.5rem;
}

.illustrationWrapper {
  @include font-size-l;

  align-items: center;
  border: 1px solid $lineColor;
  border-radius: $borderRadius;
  color: $textColorLight;
  display: flex;
  justify-content: space-around;
  margin-top: 4.5rem;
}

.illustration {
  display: flex;
  margin-top: $gapS;
}

.customToggleBtn {
  @include font-size-l;

  align-items: center;
  background-color: $inputBgColor;
  border: 0;
  border-radius: $borderRadius;
  color: $textColor;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 4rem;
  padding: $gapS $gapS;
  width: 100%;
}

.btnArrow {
  transform: rotate(-90deg);
  transition: transform ease 0.3s;
}

.btnArrowUp {
  transform: rotate(-270deg);
}

.formRow {
  align-items: center;
  display: flex;
}

.helperText {
  padding-top: 2rem;
}

.backBtn {
  height: 3.6rem;
  margin-right: 2rem;
  min-width: unset;
  width: 3.6rem;
}
