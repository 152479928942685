@import 'src/styles/mixins';

.title {
  @include font-size-xl;
}

.input {
  display: flex;
  margin-top: $gapL;
  max-width: $inputMaxWidth;
}

.error {
  margin-bottom: 1rem;
}

.select {
  width: 100%;
}

.cancelButton {
  background-color: $errorColor;
  margin-right: 1rem;

  &:hover {
    background-color: darken($color: $errorColor, $amount: 1);
  }
}

.buttonContainer {
  margin-top: $gapXL;
}
