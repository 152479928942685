@import 'src/styles/mixins';

.wrapper {
  max-width: 39.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
}

.bar {
  background-color: $primaryColor;
  border-radius: $borderRadius;
  height: 0.5rem;
  position: relative;
  width: 100%;
}

.barProgress {
  @include default-transition;

  background-color: $errorColor;
  border-radius: $borderRadius;
  height: 0.5rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 10%;
}

.text {
  @include font-size-s;
}
