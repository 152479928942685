@import "src/styles/mixins";

/* stylelint-disable no-descending-specificity */

.fieldWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.field {
  @include default-transition;

  width: 100%;
}

.input {
  @include font-size-s;

  border: 0;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $textColor;
  line-height: $gapL;
  min-height: $basicElementHeight;
  transition: border-color 0.2s ease, background-color 0.2s ease;
  outline: none;
  padding: 0 16px;
  border: 1px solid $gray_100;

  &_focused {
    border-color: $gray_400;
  }

  &_textarea {
    min-height: 1.8rem;
    padding: 0 0.1rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @include default-transition;

    box-shadow: 0 0 0 3rem $inputBgColor inset !important;

    &:disabled {
      box-shadow: 0 0 0 3rem $white inset !important;
    }
  }

  &[type="number"] {
    appearance: textfield;
  }
}

.inputWrapper {
  @include rounded-corners;

  color: $gray_600;
  background: transparent !important;

  border-radius: $borderRadius;
  min-height: $basicElementHeight;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &::after,
  &::before {
    display: none;
  }

  &_disabled {
    background: none !important;
    border-color: $switchBgColor;
  }

  &_textarea {
    padding-bottom: 0;
    padding-top: 0;
  }

  &_focused {
    border-color: $gray_400;
  }

  &_error {
    border-color: $errorColor;
  }

  &_end {
    padding-right: 0;
  }
}

.error {
  @include font-size-s;

  color: $errorColor;
  margin-top: 0.1rem;

  &_absolute {
    bottom: -1.8rem;
    position: absolute;
  }
}

.label {
  @include font-size-s;

  color: $textColorLight !important;
  pointer-events: none;
  transform: translate($gapM, 1.3rem) scale(1);
  z-index: $zIndexTop;

  &_shrink {
    transform: translate($gapM, 0.6rem) scale(0.8) !important;
  }
}

.popper {
  width: 400px;
  width: 320px;
  padding: 20px;

  & > div {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  // days container
  & > div > div:nth-child(2) {
    padding: 0 0 20px 0;
    margin: 10px 0 20px 0;
    border-bottom: 1px solid $additional_gray_light_50;

    & > div {
      margin: 0;
      padding: 0;
    }

    & > div > div {
      padding: 0;
      margin: 8;
      width: 36px;
      height: 36px;
      font-size: 14px;
      line-height: 36px;

      &:hover {
        background-color: $blue_400;
        color: $white;
        border-radius: 8px;
      }
    }
  }

  & > div > div:nth-child(1) {
    background-color: $white;
    margin: 0;
    padding: 0;
    border: none;
  }
}

.headerWrapper {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $additional_gray_light_50;

  & + div {
    & > div {
      padding: 0;
      margin: 8;
      width: 36px;
      height: 36px;
      font-size: 14px;
      line-height: 36px;
    }
  }
}

.headerYear {
  font-size: 16px;
  line-height: 28px;
  margin: 0;
}

.headerIcon {
  cursor: pointer;
}

.bottomButtons {
  & button:first-child {
    margin-right: 8px;
  }
}

.button {
  width: calc(50% - 4px);
}
