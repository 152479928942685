@import "src/styles/mixins";
@import "src/styles/variables";

.label {
  font-size: 12px;
  color: $gray_400;
  margin: 0;
}

.value {
  font-size: 14px;
  color: $gray_600;
}

.container {
  padding: 0 2rem;
}
