@import "src/styles/mixins";
@import "src/styles/variables";

.icon {
  margin-left: -$gapS;
  margin-right: $gapS;

  &_end {
    margin-left: $gapS;
    margin-right: -$gapS;
  }

  g,
  path {
    @include default-transition;
  }
}

.label {
  @include text-overflow-ellipsis;
}

.button {
  @include default-transition;
  background-color: $blue_500;
  border-color: $blue_500;
  border-width: 2px !important; // sass-lint:disable-line no-important
  height: $basicElementHeight;
  padding-left: $gapM;
  padding-right: $gapM;

  &_disabled {
    .addIcon {
      path {
        fill: $disabledColor;
      }
    }

    .saveIcon {
      path {
        stroke: $disabledColor;
      }
    }

    .arrowIcon,
    .closeIcon {
      g {
        fill: $disabledColor;
      }
    }
  }

  &:hover {
    border-width: 2px !important; // sass-lint:disable-line no-important
  }
}

.hidden {
  opacity: 0;
}
