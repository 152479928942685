@import "src/styles/mixins";

.link {
  @include font-light;

  color: $primaryColor;
  cursor: pointer;
  margin-bottom: $gapL;
  margin-top: $gapL;

  @include tablet-only {
    margin-bottom: $gapS;
  }
}

.boxRow {
  display: flex;

  @include mobile-only {
    flex-direction: column;
  }
}

.welcomeMessage {
  font-size: 3.2rem;
  margin-bottom: $gapL;
  margin-top: 10vh;
}

.signInRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.register {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: $gapL;
  text-align: center;
  width: 50%;

  @include tablet-only {
    margin-bottom: $gapL;
    margin-top: $gapL;
  }

  @include mobile-only {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }
}

.registerHeader {
  @include font-light;
  @include font-size-l;

  margin-bottom: $gapM;
}

.registerLink {
  @include font-size-l;

  margin-bottom: $gapM;

  &:visited {
    color: $primaryColor;
  }
}

.registerIllustration {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @include mobile-only {
    display: none;
  }

  @include tablet-only {
    max-width: 30rem;
  }
}

.boxHeader {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;

  @include tablet-only {
    @include font-size-m;

    margin-bottom: $gapM;
  }
}

.logo {
  @include hide-on-tablet;

  margin-bottom: $gapL;
}

.loginForm {
  box-sizing: border-box;
  width: 50%;

  @include mobile-only {
    width: 100%;
  }

  @include tablet-only {
    margin-bottom: $gapL;
    margin-top: $gapL;
  }
}

.hideOnTablet {
  @include hide-on-tablet;
}

.checkboxInvalid {
  path {
    color: $errorColor;
  }
}

.bankRow {
  @include font-light;
  @include font-size-l;

  align-items: center;
  display: flex;
  margin-bottom: $gapM;
  margin-top: $gapM;

  @include tablet-only {
    display: none;
  }
}

.institutionLogo {
  margin-left: $gapL;
  margin-right: $gapL;
}

.errorBanner {
  @include font-light;
  @include font-size-s;

  align-items: center;
  background: $errorBgColor;
  border: 2px solid $errorColor;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $errorColor;
  display: flex;
  height: 4rem;
  justify-content: center;
  width: 100%;
}

.rightWrapper {
  text-align: center;

  h2 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 14px;
    color: $gray_500;
  }
}
