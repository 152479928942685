@import 'src/styles/mixins';

.wrapper {
  padding: 2.5rem 4rem;
  width: 72rem;
}

.title {
  @include font-size-xl;

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;

  &__close {
    font-size: 2.5rem;
  }
}

.row {
  display: flex;
  padding: 1.6rem 0;
  width: 100%;
}

.label {
  @include font-size-xs;

  color: $textColorLight;
  margin-bottom: $gapS;
}

.value {
  @include font-size-s;

  align-items: center;
  color: $textColor;
  display: flex;
  height: 3rem;
}
