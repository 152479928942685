@import "src/styles/mixins";
@import "src/styles/variables";

.container {
  padding: 0 70px;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.card {
  box-shadow: none;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid $additional_gray_light_50;

  h3 {
    margin: 8px 0 4px 0;
    font-size: 14px;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 12px;
  }
}
