@import '../mixins';

$yearPickedBg: #ebebeb;
$yearHoverBg: #f5f5f5;

/* stylelint-disable selector-class-pattern */

:global {
  .MuiPickersDatePickerRoot-toolbar {
    @include font-size-l;

    height: $gapL;
    padding-left: $gapM;
    padding-right: $gapM;
  }

  .MuiPickersToolbarText-toolbarTxt,
  .MuiPickersToolbarButton-toolbarBtn,
  .MuiPickersToolbarText-toolbarBtnSelected {
    @include font-size-m;
  }

  .MuiPickersYear-root {
    @include font-size-m;
    @include default-transition;

    height: $gapL;

    &:hover {
      background: $yearHoverBg;
    }
  }

  .MuiPickersYear-yearSelected {
    @include font-size-m;

    background: $yearPickedBg;
    margin-bottom: 0;
    margin-top: 0;

    &:hover {
      background: $yearPickedBg;
    }
  }
}
