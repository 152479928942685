@import 'src/styles/variables';

.container {
  border: 1px solid black;
  max-height: 932px;
  max-width: 430px;
  width: 430px;
  background-color: $white;
}

.borderBottom {
  border-bottom: 1px solid black;
}

.header {
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 1.16rem;

  span {
    font-weight: 600;
    font-size: 1.5rem;
  }
}

.caption {
  font-weight: 600;
  margin-top: 0;
}

.image {
  height: 64px;
  width: 64px;
}

.address {
  min-height: 10rem;
}

section {
  padding: 0 1rem;
  border-bottom: 1px solid black;

  div {
    font-size: 1.16rem;
  }
}

.splitSection {
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    &:first-child {
      border-right: 1px solid black;
    }
    &:last-child {
      padding-left: 1rem;
    }
  }
}
.label {
  text-decoration: underline;
  font-weight: 300;
}

.value {
  font-weight: 600;
  font-size: 1.2rem;
}

.plainValue {
  font-weight: 400;
  font-size: 1rem;
}

.emptySection {
  height: 3rem;
}

.centered {
  text-align: center;
}
.total {
  text-align: right;
}

@media print {
  button {
    display: none;
  }
}
