@import 'mixins';

html {
  font-size: $baseFontSize;
}

* {
  box-sizing: border-box;
  font-family: $mainFontFamily;
  font-weight: normal;
}

body {
  @include font-size-m;

  color: $textColor;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  background-color: $primaryBackgroundColor;

  strong {
    font-weight: bold;
  }
}

h1 {
  @include font-size-xl;

  margin-bottom: $gapM;
  margin-top: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.MuiSelect-icon {
  right: 0.5rem;
  top: calc(50% - 8px);
}

/* stylelint-disable-next-line selector-class-pattern */
.MuiInputBase-inputTypeSearch {
  padding-bottom: 1.3rem;
}

/* stylelint-disable-next-line selector-class-pattern */
.custom-select {
  /* stylelint-disable-next-line selector-class-pattern */
  .MuiFormLabel-filled {
    z-index: 0;
  }
}
