@import "src/styles/mixins";
@import "src/styles/variables";

.footer {
  width: 100%;
  padding: 16px 70px;
  background-color: $brand_100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
