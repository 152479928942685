@import "src/styles/mixins";
@import "src/styles/variables";

.filtersContainer {
  padding: 1.8rem 2.4rem;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $gapM;
  border-top-right-radius: $borderRadius_lg;
  border-top-left-radius: $borderRadius_lg;
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  gap: $gapS;
}

.filtersTitle {
  font-size: map-get($fontSizes, m);
}

.statusInput {
  min-width: 172px;
}

.datePicker {
  & input {
    padding: 0 16px;
  }
}

.error {
  @include font-size-s;

  color: $errorColor;
}
