@import 'src/styles/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  @include font-size-xl;

  margin-bottom: $gapL;
}

.description {
  @include font-light;
  @include font-size-l;
}

.formWrapper {
  width: 100%;

  &--reset {
    width: 40rem;
  }
}

.btnWrapper {
  padding: 2.25rem 0 0;
  text-align: center;
}

.btnWrapperChangePassword {
  padding: 2.25rem 0 0;
}

.input {
  display: flex;
  margin-top: $gapL;
  width: 100%;

  &--center {
    margin: 0 auto;
  }
}

.passwordStrength {
  margin-top: $gapS;
}

.error {
  @include font-size-m;

  color: $errorColor;
}
