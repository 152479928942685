@import "src/styles/mixins";
@import "src/styles/variables";

.infoWrapper {
  line-height: 0;
  padding-bottom: 48px;
}

.start {
  font-size: 12px;
  text-transform: uppercase;
  color: $blue_500;
  text-align: center;
}

.header {
  font-size: 36px;
  text-align: center;
}

.container {
  padding: 48px 0;
}
