@import "src/styles/mixins";

.link {
  color: $gray_600;
  text-decoration: none;
  cursor: pointer;
  padding: 8px 0;
}

.input {
  display: flex;
  margin-top: $gapL;
}

.error {
  @include font-size-s;

  color: $errorColor;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.signInRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: $gapL;
  max-width: $inputMaxWidth;
}

.terms {
  margin: 16px 0 32px 0;
}

.button {
  width: 190px;
  margin-left: auto;
}

.register {
  font-size: 13px;
  font-weight: 600;
  color: $gray_300;
  margin-top: 48px;

  a {
    text-decoration: none;
    color: $gray_600;
  }
}
