@import 'src/styles/mixins';

.main {
  display: flex;
}

.title {
  @include font-size-xl;
}

.pageHeader {
  margin-bottom: $gapM;
}

.filtersContainer {
  margin-bottom: $gapL;
}

.tableHeader {
  margin-bottom: $gapL;
  margin-top: $gapL;
}

.tableTitle {
  @include font-size-l;
}

.searchInput {
  width: 33rem;
}

.tableContainer {
  display: flex;
  flex: 1 1;
  flex-flow: column;
}

.orderNo {
  color: $textColorLight;
}

.statusCellNeutral {
  color: $textColorLight;
}

.statusCellPositive {
  color: $successColor;
}

.statusCellNegative {
  color: $errorColor;
}
