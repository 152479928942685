@import 'src/styles/mixins';

.datePicker {
  margin-top: $basicElementHeight;
}

.iconWrapper {
  height: $basicElementHeight;
  max-height: $basicElementHeight;
  position: absolute;
  right: $gapS;
  top: 0;

  button {
    padding: 0.7rem;
  }
}

.error {
  @include font-size-s;

  color: $errorColor;
  margin-top: $gapS;
}
