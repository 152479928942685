@import "src/styles/mixins";
@import "src/styles/variables";

.cardLabel {
  padding: 6px 10px;
  font-size: 12px;
  width: fit-content;
  border-radius: 4px;
  margin: 0;
}

.primary {
  background-color: $blue_100;
  color: $blue_500;
}

.secondary {
  background-color: $label_card_100;
  color: $label_card_400;
}
